import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import * as sdk from 'matrix-js-sdk';
import { MatrixUser, ProfileType, SettingsType } from '../../../types';
import { API, HOST } from '../../../const';
import { authProvider } from '../../../utils';
import { AppContainer, SettingIcon, CloseIcon } from '../../../components';
import { Settings } from './Settings';
import { Profile } from './Profile';

export const ProfilePage = () => {
  const [userProfile, setUserProfile] = useState<ProfileType>();
  const [settingsIsOpened, setSettingsIsOpened] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);

  const [client, setClinet] = useState<any>();
  const [matrixUser, setMatrixUser] = useState<MatrixUser>();

  const { token, setProfile, login } = authProvider();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const getProfile = async () => {
    try {
      const response = await fetch(`${API}/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        setUserProfile(data);
        setProfile(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const putProfile = async (values: SettingsType) => {
    try {
      await fetch(`${API}/profile`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }).then(() => {
        getProfile();
        setSettingsIsOpened(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const initMatrix = async () => {
    try {
      const response = await fetch(`${API}/profile/matrix/login`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const data: MatrixUser = await response.json();
        setMatrixUser(data);

        const client = sdk.createClient({
          baseUrl: `https://${data.home_server}/`,
          userId: data.user_id,
          deviceId: data.device_id,
          accessToken: data.access_token,
        });

        setClinet(client);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const changeMatrixAvatar = async () => {
    const response = await fetch(`${HOST}${userProfile?.avatar}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const blob = await response.blob();

    if (client) {
      const res = await client.uploadContent(blob, {
        name: 'avatar',
        type: `image/${userProfile?.avatar.split('.')[userProfile?.avatar.split('.').length - 1]}`,
        includeFilename: true,
      });

      await client.setAvatarUrl(res.content_uri);
    }
  };

  const uploadAvatar = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file, file.name);
    try {
      await fetch(`${API}/profile/avatar`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }).then(() => {
        onCloseSettings();
        getProfile();
        changeMatrixAvatar();
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onOpenSettings = () => {
    setSettingsIsOpened(true);
  };

  const onCloseSettings = () => {
    setSettingsIsOpened(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  useEffect(() => {
    let token = '';
    if (searchParams.has('token')) {
      token = searchParams.get('token') || '';
    }
    if (token) {
      login(token);

      if (navigator.userAgent.includes('iPhone')) {
        window.location.replace(`doctorly://auth/result?token=${token}`);
        setTimeout(() => {
          navigate(`/auth/result?token=${token}`);
        }, 1000);
      } else {
        setSearchParams('');
        window.location.reload();
      }
    }

    getProfile();
    initMatrix();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <AppContainer title=""></AppContainer>;
  }

  return (
    <AppContainer
      title={settingsIsOpened ? 'Настройки' : 'Профиль'}
      icon={settingsIsOpened ? <CloseIcon /> : <SettingIcon />}
      action={settingsIsOpened ? onCloseSettings : onOpenSettings}
    >
      {settingsIsOpened && (
        <Settings
          profile={userProfile}
          onUploadAvatar={uploadAvatar}
          onSave={putProfile}
          goBack={onCloseSettings}
        />
      )}

      {!settingsIsOpened && (
        <Profile profile={userProfile} matrixUser={matrixUser} client={client} />
      )}
    </AppContainer>
  );
};
