import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageContainer } from '../components';

export const PaymentPage = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has('status')) {
      const status = searchParams.get('status') || '';
      window.localStorage.setItem('PAYMENT_STATUS', status.toString());
    }
  }, [searchParams]);

  return <PageContainer isFull></PageContainer>;
};
