import { styled } from 'styled-components';

import { COLORS } from '../../const';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 450px;
  height: 100dvh;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 125px;
  display: flex;
  flex-direction: column;
  color: #000;
  gap: 16px;
  overflow: auto;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
`;

export const Bottom = styled.div`
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 0;
  padding-top: 9px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #edeff5;
`;

export const DoctorRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

// PICKER

export const PickerContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: -16px;
  right: -16px;
  background-color: ${COLORS.main_white};
`;

export const PickerButtons = styled.div`
  position: absolute;
  top: 4px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
`;

export const Option = styled.div.withConfig({
  shouldForwardProp: (p) => !['selected'].includes(p),
})<{
  selected?: boolean;
}>`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background-color: ${(props) => (props.selected ? '#EFEFF0' : COLORS.main_white)};
`;

export const PickerPrice = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
