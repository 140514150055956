import React, { FC, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MatrixUser, ProfileType, SettingsType } from '../../../types';
import { PostIcon, Text, AppButton, Avatar } from '../../../components';
import { authProvider } from '../../../utils';
import {
  ProfileContainer,
  ProfileWrapper,
  NameWrapper,
  ProfileTabList,
  ProfileTab,
  ButtonWrapper,
} from './styled';

import checkImgPath from '../../../assets/icons/check-surveys.svg';
import logoutPath from '../../../assets/icons/logout.svg';
// import calendarPath from '../../../assets/icons/calendar.svg';
import cardPath from '../../../assets/icons/card.svg';
import pillsPath from '../../../assets/icons/pills.svg';
import { CHAT_HOST, HOST, IS_DEV } from '../../../const';

export type ProfileProps = {
  profile?: ProfileType;
  matrixUser?: MatrixUser;
  onUploadAvatar?: (file: File) => Promise<void>;
  onSave?: (values: SettingsType) => void;
  goBack?: () => void;
  client?: any;
};

export const Profile: FC<ProfileProps> = ({ profile, matrixUser, client }) => {
  const { logout, token } = authProvider();
  const navigate = useNavigate();

  const avatarPath = profile?.avatar;

  const isDoctor = useMemo(() => {
    return profile?.is_manager || profile?.type === 'doctor' || profile?.type === 'clinic';
  }, [profile]);

  const onLogout = () => {
    logout();
    navigate('/auth');
  };

  const checkMatrixUserData = async () => {
    if (client) {
      const userData = await client.getProfileInfo(matrixUser?.user_id || '');

      if (userData) {
        const fullName = `${profile?.last_name} ${profile?.first_name} ${profile?.patronymic}`;
        if (!userData.displayname || userData.displayname !== fullName) {
          await client.setDisplayName(fullName);
        }

        if (!userData.avatar_url) {
          if (profile?.avatar) {
            const response = await fetch(`${HOST}${profile.avatar}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const blob = await response.blob();
            const res = await client.uploadContent(blob, {
              name: 'avatar',
              type: `image/${profile.avatar.split('.')[profile.avatar.split('.').length - 1]}`,
              includeFilename: true,
            });

            await client.setAvatarUrl(res.content_uri);
          }
        }
      }
    }
  };

  const onOpenChat = () => {
    const url = `${CHAT_HOST}?accessToken=${matrixUser?.access_token}&userId=${matrixUser?.user_id}&deviceId=${matrixUser?.device_id}&&dev=${IS_DEV}`;

    if (client) {
      checkMatrixUserData();
    }

    window.open(url, '_blank');
  };

  return (
    <ProfileContainer>
      <ProfileWrapper>
        <Avatar size="small" path={avatarPath || ''} />

        <NameWrapper>
          <Text weight={600}>
            {profile?.last_name} {profile?.first_name}
          </Text>
          <Text size={14} color="#787D93">
            {profile?.username}
          </Text>
        </NameWrapper>
      </ProfileWrapper>

      {isDoctor && (
        <ProfileTabList>
          <Link to="/newpost">
            <ProfileTab>
              <PostIcon color="#76AC98" />
              <Text weight={600}>Создать пост</Text>
            </ProfileTab>
          </Link>

          <Link to="/profile/cabinet">
            <ProfileTab>
              <img src={checkImgPath} alt="check" />
              <Text weight={600}>Проверка опросов</Text>
            </ProfileTab>
          </Link>

          <button onClick={onOpenChat}>
            <ProfileTab>
              <Text weight={600}>Перейти в чат</Text>
            </ProfileTab>
          </button>
        </ProfileTabList>
      )}

      {!isDoctor && (
        <ProfileTabList>
          <Link to="/medcard">
            <ProfileTab>
              <img src={cardPath} alt="card" />
              <Text weight={600}>Медицинская карта</Text>
            </ProfileTab>
          </Link>

          <Link to="course">
            <ProfileTab>
              <img src={pillsPath} alt="pills" />
              <Text weight={600}>Курсы лекарств</Text>
            </ProfileTab>
          </Link>

          {/* <Link to="calendar">
            <ProfileTab>
              <img src={calendarPath} alt="calendar" />
              <Text weight={600}>Календарь приема лекарств</Text>
            </ProfileTab>
          </Link> */}
        </ProfileTabList>
      )}
      <ButtonWrapper>
        <AppButton title="Выйти" onClick={onLogout} icon={<img src={logoutPath} alt="logout" />} />
      </ButtonWrapper>
    </ProfileContainer>
  );
};
